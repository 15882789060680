import Fuse from "fuse.js";
import { useState } from 'react';
import SelectSearch from "react-select-search";
import { ManufacturerTexts } from '../../../language/Texts';
import { GeneralAttributes } from '../../../models/GeneralAttributes';
import { INetilionResponse } from '../../../models/INetilionResponse';
import './Manufacturer.scss';

interface ManufacturerProps {
    selectableManufacturers: string | undefined,
    setManufacturer: (manufacturer: string) => void,
    setNetilionResponse: (netilionResponse: INetilionResponse) => void,
    loading: boolean
}

const Manufacturer = ({ selectableManufacturers, setManufacturer, setNetilionResponse, loading }: ManufacturerProps) => {
    const [value, setValue] = useState<any>("");

    let filterOptions = (options) => {
        const fuse = new Fuse(options, {
            keys: ["name"],
            maxPatternLength: 1,
            maxResults: 25,
            threshold: 0.8
        });
        return (value) => {
            if (!value.length) {
                return options;
            }
            return fuse.search(value);
        };
    }

    let options: { name: string, value: string }[] = []

    let selectedManufactures = (selectableManufacturers ?? (sessionStorage.getItem("selectableManufacturers") as string))?.replaceAll(' ', '').split(",")

    if (selectedManufactures && selectedManufactures.length > 0) {
        for (let manufacturer of selectedManufactures) {
            switch (manufacturer) {
                case "EH":
                    options.push({ name: GeneralAttributes.netilion, value: GeneralAttributes.netilion })
                    break
                case "Ari":
                    options.push({ name: GeneralAttributes.ari, value: GeneralAttributes.ari })
                    break
                case "Krohne":
                    options.push({ name: GeneralAttributes.krohne, value: GeneralAttributes.krohne })
                    break
                case "Bray":
                    options.push({ name: GeneralAttributes.bray, value: GeneralAttributes.bray })
                    break
                case "Samson":
                    options.push({ name: GeneralAttributes.samson, value: GeneralAttributes.samson })
                    break
                case "Gea":
                    options.push({ name: GeneralAttributes.gea, value: GeneralAttributes.gea })
                    break
                default:
                    console.log('**SELECTABLE_MANUFACTURERS** Enviroment options are not correct:', selectedManufactures);
            }
        }
    } else {
        options = [
            { name: GeneralAttributes.netilion, value: GeneralAttributes.netilion },
            { name: GeneralAttributes.ari, value: GeneralAttributes.ari },
            { name: GeneralAttributes.krohne, value: GeneralAttributes.krohne },
            { name: GeneralAttributes.ari, value: GeneralAttributes.bray }
        ]
    }

    const handleValue = (e) => {
        setValue(e)
        setManufacturer(e)
        setNetilionResponse({
            serialNumber: "",
        })
    }

    return (
        <div className="manufacturer-wrapper">
            <p className="select-title">
                {ManufacturerTexts.chooseManufacturer}
            </p>

            <div className="search-wrapper">
                <SelectSearch
                    disabled={loading}
                    options={options}
                    value={value}
                    onChange={(e) => handleValue(e)}
                    search
                    filterOptions={(options) => filterOptions(options)}
                    placeholder={ManufacturerTexts.chooseManufacturer}
                />

            </div>
        </div>
    )
}

export default Manufacturer
import version from '../../version.json'
import FooterLinksBar from '../FooterLinksBar/FooterLinksBar'
import './Footer.scss'

function Footer({ backendVersion, baseUrl, isLoggedIn, setIsDocuOpen, isDocuOpen }) {
    const getBackendVersion = () => {
        if (sessionStorage.getItem('backendVersion')) {
            return "BE: " + sessionStorage.getItem('backendVersion')
        } else if (backendVersion) {
            return "BE: " + backendVersion
        } else {
            return ''
        }
    }

    const getFrontendVersion = () => {
        return "FE: " + version.version
    }

    const displayImpirintAndDatasecurity = () => {
        if (sessionStorage.getItem('isLoggedIn') || isLoggedIn) {
            return <FooterLinksBar
                baseUrl={baseUrl}
                setIsDocuOpen={setIsDocuOpen}
                isDocuOpen={isDocuOpen}
            />
        } else {
            return null
        }
    }

    return (
        <div className='footer_wrapper'>
            <span> {displayImpirintAndDatasecurity()}</span>
            <span> {getFrontendVersion()}&nbsp; {getBackendVersion()}</span>
        </div>
    )
}

export default Footer

import axios from 'axios'
import { IBackendInfo } from '../models/IBackendInfo'
import { IHttpResult } from '../models/IHttpResult'
import { ILoginAttributes } from '../models/LoginAttributes'
import { RequestPaths } from '../models/RequestPaths'
import { BackendResult } from './BackendResult'
import { Utils } from './Utils'

export class HttpRequest {

    async login(data: ILoginAttributes): Promise<IHttpResult> {
        return new Promise((resolve, reject) => {

            axios.post(RequestPaths.loginPath, data, { withCredentials: true }).then(result => {
                resolve(result.data)
            }).catch(err => {
                if (err.response) {
                    console.log("Error on Login, Status Code: ", err.response.status)
                    reject(err)
                }
                else {
                    console.log('err', err);
                    reject(err)
                }
            })
        })
    }

    async getFromNetilion(serial_number: string): Promise<BackendResult> {
        let data = { serial_number }
        return axios.post(RequestPaths.netilionIdCheck, data, { withCredentials: true })
            .then(result => result.data)
            .catch(err => {
                throw err
            })
    }

    // save in conexo
    async saveInConexo(url: string, uploadData: { digitalLink: string }): Promise<BackendResult> {
        uploadData = { ...uploadData, ...Utils.getSystemInfo() }
        return axios.post(url, uploadData, { withCredentials: true })
            .then(result => result.data)
            .catch(err => {
                throw err
            })
    }

    async getPermissions(url: string) {
        return new Promise((resolve, reject) => {
            axios.get(url, { withCredentials: true }).then(result => {
                resolve(result.data)
            }).catch(error => {
                reject(error)
            })
        })
    }

    async getBackendInfo(url: string): Promise<IBackendInfo> {
        return new Promise((resolve, reject) => {
            axios.get(url, { withCredentials: true }).then(result => {
                resolve(result.data)
            }).catch(error => {
                reject(error)
            })
        })
    }

}
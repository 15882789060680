import { faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralTexts, LoginTexts } from '../../language/Texts';
import { RequestPaths } from '../../models/RequestPaths';
import './FooterLinksBar.scss';

interface ImpirintDatasecurityProps {
    baseUrl: string
    setIsDocuOpen?: (isOpen: boolean) => void
    isDocuOpen?: boolean
}

function FooterLinksBar({ baseUrl, setIsDocuOpen, isDocuOpen }: ImpirintDatasecurityProps) {

    const getBaseUrl = () => {
        if (sessionStorage.getItem('baseUrl')) {
            return sessionStorage.getItem('baseUrl') + RequestPaths.impressumPath
        } else if (baseUrl) {
            return baseUrl + RequestPaths.impressumPath
        } else {
            return '#'
        }
    }

    const handleHelpClick = () => {
        setIsDocuOpen!(!isDocuOpen!)
    }

    const documentationLink = () => {
        return (
            <>
                {setIsDocuOpen &&
                    <>
                        &ensp; | &ensp;
                        <span className='single_link'
                            onClick={() => handleHelpClick()}>
                            {GeneralTexts.help}  &nbsp;   <FontAwesomeIcon className="sidebar_icon" icon={faBook} />
                        </span>
                    </>
                }
            </>
        )
    }

    const dataSecurityLink = () => {
        return (
            <a className='single_link'
                data-testid="data_security"
                href=" https://www.inevvo-solutions.com/de/datenschutz-portal/"
                target="_blank"
            >{GeneralTexts.dataSecurity}</a>
        )
    }

    return (
        <div className="link_bar_wrapper">
            {dataSecurityLink()}
            &ensp; | &ensp;
            <a className='single_link' href={"//" + getBaseUrl()} target="_blank">{LoginTexts.imprint}</a>
            {documentationLink()}
        </div>
    )
}

export default FooterLinksBar

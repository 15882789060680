import { UploadTexts } from '../../../language/Texts';
import { GeneralAttributes } from '../../../models/GeneralAttributes';
import { RequestPaths } from '../../../models/RequestPaths';
import { UploadProps } from '../../../models/UploadProps';
import BoxComponent from '../../BoxComponent/BoxComponent';

const ConexoUploadAri = ({ manufacturer, setConexoResponse, setLoading, loading, resetSession }: UploadProps) => {
    const url = RequestPaths.saveInConexoPathAri

    return (
        <>
            {manufacturer === GeneralAttributes.ari &&
                <BoxComponent
                    boxTitle={UploadTexts.qrCodeScannenAri}
                    setConexoResponse={setConexoResponse}
                    inputType='url'
                    placeholder={UploadTexts.inputIdLink}
                    buttonText={UploadTexts.saveInConexo}
                    setLoading={setLoading}
                    loading={loading}
                    requestUrl={url}
                    resetSession={resetSession}
                />
            }
        </>
    )
}

export default ConexoUploadAri
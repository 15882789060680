import { ChangeEvent, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { ErrorTexts, UploadTexts } from '../../language/Texts';
import { BackendResult } from '../../utils/BackendResult';
import { HttpRequest } from '../../utils/HttpRequest';
import { Utils } from '../../utils/Utils';
import ModalApp from '../Modal/Modal';
import qrCode from '../../assets/qrCode.png';
import datamatrix from '../../assets/datamatrix.png';
import './BoxComponent.scss';

interface BoxProps {
    boxTitle: string,
    setConexoResponse: (value: any) => void,
    inputType: string,
    placeholder: string,
    buttonText: string,
    setLoading: (value: boolean) => void,
    loading: boolean,
    formSubmit?: (event: any) => Promise<void>,
    setNetilionInput?: (value: string) => void,
    netilionInput?: string,
    requestUrl?: string,
    isManufacturerKrohne?: boolean,
    setNetilionResponse?: (value: any) => void,
    isConexoUploadNetilion?: boolean,
    allowedNetilionUrls?: string
    resetSession: () => void
}

function BoxComponent({
    boxTitle,
    setConexoResponse,
    inputType,
    placeholder,
    buttonText,
    setLoading,
    loading,
    formSubmit,
    netilionInput,
    setNetilionInput,
    requestUrl,
    isManufacturerKrohne,
    setNetilionResponse,
    isConexoUploadNetilion,
    allowedNetilionUrls,
    resetSession
}: BoxProps) {

    const [inputValue, setInputValue] = useState('')
    const [onClose, setOnClose] = useState(false);
    const [alertOpt, setAlertOpt] = useState({ message: '', isOpen: false });

    function toggleModal() {
        setOnClose(!onClose);
    }

    const isKrohneDigitalLinkCorrect = (): boolean => {
        if (inputValue.startsWith('dev-sn.com/')) {
            return true
        } else {
            setAlertOpt({ message: ErrorTexts.wrongFormat, isOpen: true })
            setTimeout(() => {
                setAlertOpt({ message: "", isOpen: false })
            }, 5000)
            return false
        }
    }

    const isNetilionDigitalLinkCorrect = (): boolean => {
        if (Utils.isNetilionUrlCorrect(inputValue, allowedNetilionUrls!)) {
            return true
        } else {
            setAlertOpt({ message: ErrorTexts.unacceptableUrl, isOpen: true })
            setTimeout(() => {
                setAlertOpt({ message: "", isOpen: false })
            }, 5000)
            return false
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if (isManufacturerKrohne && !isKrohneDigitalLinkCorrect()) {
            return
        }

        if (isConexoUploadNetilion && !isNetilionDigitalLinkCorrect()) {
            return
        }

        setLoading(true)
        try {
            const conexoResponse = await new HttpRequest().saveInConexo(requestUrl!, { digitalLink: inputValue })
            setConexoResponse(conexoResponse)
        } catch (error) {
            console.error('error on submit', error);
            errorHandling(error)
        }
        setLoading(false)
        setInputValue('')
        if (setNetilionInput) {
            setNetilionInput('')
        }
    };

    const errorHandling = (error) => {
        if (error?.response?.status === 401) {
            resetSession()
            return
        }
        const errorMessage = error.response?.data ?? error.response?.statusText ?? ErrorTexts.unexpectedError
        setConexoResponse(new BackendResult(false, null, errorMessage))
    }

    // If the input is string and not url, the formSubmit function is used, that is getted by props from nertilion upload 
    // Otherwise, the onSubmit function is used
    const getFormSubmit = () => {
        if (formSubmit && !Utils.isValidUrl(netilionInput!)) {
            return formSubmit
        } else {
            return onSubmit
        }
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setConexoResponse('')
        setInputValue(e.target.value.trim())
        if (setNetilionInput) {
            setNetilionInput(e.target.value.trim())
        } else {
            setInputValue(e.target.value.trim())
        }
    }

    const handleOnFocus = () => {
        setConexoResponse('')
        if (setNetilionResponse) {
            setNetilionResponse('')
        }
    }

    return (
        <div className="box_component_wrapper">
            <Alert className="alert" variant="warning" show={alertOpt.isOpen} >
                {alertOpt.message}
            </Alert>

            <p className="box_title">{boxTitle}</p>
            <form onSubmit={getFormSubmit()}>
                <div className="input_wrapper">
                    <img
                        className="box_image"
                        src={isManufacturerKrohne ? datamatrix : qrCode}
                        alt="Qr Code"
                        onClick={toggleModal}
                        style={loading ? { pointerEvents: 'none', opacity: 0.7 } : {}} />

                    <div className="box_input_group">
                        <Button
                            disabled={loading}
                            className="scan_button box_button_group"
                            variant="outline-dark"
                            bsPrefix="scan_button"
                            onClick={toggleModal}
                            style={loading ? { pointerEvents: 'none', opacity: 0.7 } : {}}>
                            {UploadTexts.scan}
                        </Button>
                        <input
                            disabled={loading}
                            type={inputType}
                            required={true}
                            className="box_input"
                            placeholder={placeholder}
                            onChange={(e) => handleOnChange(e)}
                            value={netilionInput ?? inputValue}
                            onFocus={() => handleOnFocus()}
                        />
                    </div>
                </div>

                <Button
                    disabled={loading}
                    type="submit"
                    className="submit_button box_button_group"
                    variant="primary"
                    bsPrefix="submit_button"
                    style={loading ? { pointerEvents: 'none', opacity: 0.7 } : {}}
                >{buttonText} </Button>

                <ModalApp
                    toggleModal={toggleModal}
                    onClose={onClose}
                    setInputValue={setInputValue}
                    setOnClose={setOnClose} />
            </form>
        </div>
    )
}

export default BoxComponent

import { useEffect, useState } from 'react';
import './App.scss';
import Footer from './components/Footer/Footer';
import AppHeader from './components/Header/AppHeader';
import Login from "./components/Login/Login";
import Main from "./components/Main/Main";
import ConfirmDialog from './components/confirmDialog/ConfirmDialog';
import i18n from './language/i18n';
import { RequestPaths } from './models/RequestPaths';
import { HttpRequest } from './utils/HttpRequest';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [backendVersion, setBackendVersion] = useState<any>()
    const [baseUrl, setBaseUrl] = useState('')
    const [storedLoggedIn, setstoredLoggedIn] = useState(sessionStorage.getItem('isLoggedIn'))
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [language, setLanguage] = useState(i18n.language);
    const [isDocuOpen, setIsDocuOpen] = useState(false)

    const getBackendLoginInfo = async () => {
        try {
            const { backendVersion, baseUrl } = await new HttpRequest().getBackendInfo(RequestPaths.getBackendLoginInfo)
            setBackendVersion(backendVersion)
            setBaseUrl(baseUrl)
            sessionStorage.setItem('backendVersion', backendVersion)
            sessionStorage.setItem('baseUrl', baseUrl)
        } catch (error) {
            console.log("Error on getting version number from backend :", error)
        }
    }

    useEffect(() => {
        if (!sessionStorage.getItem('backendVersion')) {
            getBackendLoginInfo()
        }
    }, [])

    const resetSession = () => {
        setIsLoggedIn(false)
        setstoredLoggedIn(null)
    }

    return (
        <div className='app_wrapper'>
            <AppHeader
                setLanguage={setLanguage}
                language={language}
            />
            {(isLoggedIn || storedLoggedIn) ?
                <Main
                    setIsModalOpen={setIsModalOpen}
                    resetSession={resetSession}
                    isDocuOpen={isDocuOpen}
                    language={language}
                /> :
                <Login
                    setIsLoggedIn={setIsLoggedIn}
                    baseUrl={baseUrl}
                />
            }
            <Footer
                isLoggedIn={isLoggedIn}
                baseUrl={baseUrl}
                backendVersion={backendVersion}
                setIsDocuOpen={setIsDocuOpen}
                isDocuOpen={isDocuOpen}
            />
            <ConfirmDialog
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            />
        </div>
    );
}

export default App;


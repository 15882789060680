import { Resizable } from "re-resizable";
import { useEffect, useState } from "react";
import { useIdleTimer } from 'react-idle-timer';
import { IdleTimerEvents } from "../../models/IdleTimerEvent";
import { INetilionResponse } from "../../models/INetilionResponse";
import { IPermissionLists } from "../../models/IPermissionLists";
import { RequestPaths } from "../../models/RequestPaths";
import { BackendResult } from "../../utils/BackendResult";
import { HttpRequest } from "../../utils/HttpRequest";
import { PermissionCheck } from "../../utils/PermissionCheck";
import Loading from "../Loading/Loading";
import NoPermissionInfo from "../NoPermissionInfo/NoPermissionInfo";
import UnexpectedError from "../UnexpectedError/UnexpectedError";
import ConexoResponse from "./ConexoResponse/ConexoResponse";
import ConexoUploadAri from "./ConexoUploadAri/ConexoUploadAri";
import ConexoUploadBray from "./ConexoUploadBray/ConexoUploadBray";
import ConexoUploadGea from "./ConexoUploadGea/ConexoUploadGea";
import ConexoUploadKrohne from "./ConexoUploadKrohne/ConexoUploadKrohne";
import ConexoUploadNetilion from "./ConexoUploadNetilion/ConexoUploadNetilion";
import ConexoUploadSamson from "./ConexoUploadSamson/ConexoUploadSamson";
import "./Main.scss";
import Manufacturer from "./Manufacturer/Manufacturer";
import NetilionResponse from "./NetilionResponse/NetilionResponse";
import NetilionUpload from "./NetilionUpload/NetilionUpload";
import i18n from '../../language/i18n';

interface MainProps {
    setIsModalOpen: (isOpen: boolean) => void
    resetSession: () => void
    isDocuOpen: boolean,
    language: string
}

const Main = ({ setIsModalOpen, resetSession, isDocuOpen, language }: MainProps) => {
    const [netilionResponse, setNetilionResponse] = useState<INetilionResponse>();
    const [conexoResponse, setConexoResponse] = useState<BackendResult | null>();
    const [manufacturer, setManufacturer] = useState("");
    const [hasPermission, setHasPermission] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false)
    const [unexpectedError, setUnexpectedError] = useState<boolean>(false)
    const [selectableManufacturers, setSelectableManufacturers] = useState<string>()
    const [allowedNetilionUrls, setAllowedNetilionUrls] = useState<string>()
    const initialWidth = sessionStorage.getItem('editorWidth') || "50%";
    const [editorWidth, setEditorWidth] = useState(initialWidth);

    const iframeSrc = `/build/${language}/docs/intro/`

    const resizableEnable = {
        top: false,
        right: true,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
    };
    const getPermissions = async () => {
        const url: string = RequestPaths.getPermissions
        try {
            const permissionLists = await new HttpRequest().getPermissions(url) as unknown as IPermissionLists
            setHasPermission(PermissionCheck.check(permissionLists))
        } catch (error: any) {
            if (error?.response?.status === 401) {
                resetSession()
                return
            }
            setUnexpectedError(true)
        }
    }

    const getBackendGeneralInfo = async () => {
        try {
            const { selectableManufacturers, allowedNetilionUrls } = await new HttpRequest().getBackendInfo(RequestPaths.getBackendGeneralInfo)
            setSelectableManufacturers(selectableManufacturers)
            setAllowedNetilionUrls(allowedNetilionUrls)

            sessionStorage.setItem('selectableManufacturers', selectableManufacturers)
            sessionStorage.setItem('allowedNetilionUrls', allowedNetilionUrls)
        } catch (error) {
            console.log("Error on getting version number from backend :", error)
        }
    }

    useEffect(() => {
        { hasPermission === undefined && getPermissions() }
        setConexoResponse(null)
    }, [netilionResponse]);

    useEffect(() => {
        if (!sessionStorage.getItem('selectableManufacturers') || !sessionStorage.getItem('allowedNetilionUrls'))
            getBackendGeneralInfo()
    }, [])

    useEffect(() => {
        sessionStorage.setItem('editorWidth', editorWidth);
    }, [editorWidth]);

    const permissonView = () => {
        return (
            <div className="permission_view">
                {hasPermission === undefined ? <Loading /> :
                    <NoPermissionInfo />}
            </div>
        )
    }
    const unexpectedErrorView = () => {
        return (
            <UnexpectedError />
        )
    }

    const mainView = () => {
        return (
            <div className="main-view-content">
                <div className="form-wrapper">
                    <Manufacturer
                        selectableManufacturers={selectableManufacturers}
                        setManufacturer={setManufacturer}
                        setNetilionResponse={setNetilionResponse}
                        loading={loading}
                    />
                    <NetilionUpload
                        setNetilionResponse={setNetilionResponse}
                        setConexoResponse={setConexoResponse}
                        manufacturer={manufacturer}
                        setLoading={setLoading}
                        loading={loading}
                        resetSession={resetSession}
                    />
                    <NetilionResponse
                        netilionResponse={netilionResponse!}
                        loading={loading}
                        manufacturer={manufacturer}
                    />
                    <ConexoUploadNetilion
                        netilionResponse={netilionResponse!}
                        setConexoResponse={setConexoResponse}
                        setLoading={setLoading}
                        loading={loading}
                        allowedNetilionUrls={allowedNetilionUrls}
                        resetSession={resetSession}
                    />
                    <ConexoUploadAri
                        manufacturer={manufacturer}
                        setConexoResponse={setConexoResponse}
                        setLoading={setLoading}
                        loading={loading}
                        resetSession={resetSession}
                    />
                    <ConexoUploadKrohne
                        manufacturer={manufacturer}
                        setConexoResponse={setConexoResponse}
                        setLoading={setLoading}
                        loading={loading}
                        resetSession={resetSession}
                    />
                    <ConexoUploadBray
                        manufacturer={manufacturer}
                        setConexoResponse={setConexoResponse}
                        setLoading={setLoading}
                        loading={loading}
                        resetSession={resetSession}
                    />
                    <ConexoUploadSamson
                        manufacturer={manufacturer}
                        setConexoResponse={setConexoResponse}
                        setLoading={setLoading}
                        loading={loading}
                        resetSession={resetSession}
                    />
                    <ConexoUploadGea
                        manufacturer={manufacturer}
                        setConexoResponse={setConexoResponse}
                        setLoading={setLoading}
                        loading={loading}
                        resetSession={resetSession}
                    />
                    <ConexoResponse
                        netilionResponse={netilionResponse!}
                        conexoResponse={conexoResponse!}
                        loading={loading}
                        manufacturer={manufacturer}
                    />
                </div>

                {isDocuOpen && <Resizable
                    className="resizable"
                    defaultSize={{ width: editorWidth, height: "auto" }}
                    handleStyles={{
                        left: { left: 0, border: "1px solid rgba(90,182,243,0.5)", width: 0 },
                    }}
                    enable={resizableEnable}
                    onResizeStop={(e, direction, ref, d) => {
                        setEditorWidth(ref.style.width);
                    }}
                >
                    <iframe
                        src={iframeSrc}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        title="Docusaurus Docs"
                    />
                </Resizable>}
            </div>
        )
    }

    const setView = () => {
        if (unexpectedError) {
            return unexpectedErrorView()
        } else if (hasPermission) {
            return mainView()
        } else {
            return permissonView()
        }
    }

    /******* TIMER *******/
    // if the user is not active, popup is displayed
    const onIdle = () => {
        sessionStorage.removeItem('isLoggedIn')
        setIsModalOpen(true)
    }
    // In order to set the time , that user can be inactive
    // events can be configured from IdleTimerEvents
    const { } = useIdleTimer({
        onIdle,
        timeout: 50 * 60 * 1000,
        events: IdleTimerEvents,
        debounce: 500,
    })
    /******* TIMER *******/

    return (
        <div className="main-wrapper">
            {setView()}
        </div>
    );
};

export default Main;
